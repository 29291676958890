import React from "react";
import Loginform from "../components/Utilities/Loginform";
import banner from "../assets/images/contactus.png";
import Cards from "../components/Utilities/Cards";
import Navbar from "../components/Navbar";
import FixedNavbar from "../components/Utilities/FixedNavbar";
import ScrollToTop from "../components/Utilities/ScrollToTop";

function Contactus() {
  return (
    <div className="bg-[#F9F7F5]">
      <ScrollToTop />
      <Navbar />
      <FixedNavbar />
      <div className=" flex flex-col space-y-16 justify-center items-center md:pt-10">
        <h1 className="text-[22px] leading-7 sm:text-3xl cards font-medium text-center px-2 pt-4 sm:pt-4 text-[#4e1e10]">
          Leave us a message and we will get back to you as soon as possible.
        </h1>
        <div className="lg:flex justify-evenly lg:space-x-7 items-center">
          <img src={banner} alt="" className=" hidden lg:block max-w-[400px]" />
          <Loginform />
        </div>
        <Cards />
      </div>
    </div>
  );
}

export default Contactus;
