import React, { memo } from "react";
import Container from "./Utilities/Container";
import { BsArrowRight } from "react-icons/bs";
import main from "../assets/images/hp.png";
import NavBars from "./NavBars";
import {Link} from 'react-router-dom'

const Header = memo(() => {
  return (
    <div className="">
    <NavBars/>
      <Container className="flex justify-content-center items-center headerHeight">
        <div className=" flex flex-col lg:flex-row items-center">
          <div className=" xl:pl-10 basis-1/2">
            <h1 className="lg:pt-6 lg:pt-0 text-[26px] sm:text-[44px] md:text-[50px] leading-8 sm:leading-[50px] xl:leading-[60px] font-semibold text-[#4e1e10]">
              Elevate your brand <br className="" /> with custom printing solutions.
            </h1>
            <p className=" py-8 text-base leading-6">
              We help small businesses create unique brand identity that
              separates them from their competitors by providing quality custom
              printing solutions.
            </p>
            <Link to="/contact">
            <button className="btn-style-one theme-btn">
            <span className="btn-wrap">
              <span className="text-one">Get Quote</span>
              <span className="text-two">Get Quote</span>
            </span>
          </button>
          </Link>
          </div>
          <div className="pt-6 lg:pt-0 flex items-center justify-center basis-1/2">
            <img src={main} alt="" className="lg:w-[600px] block headanim" />
          </div>
        </div>
      </Container>
    </div>
  );
});

export default Header;
