const Preloader = () => {
  return (
    <div
      className="fixed bg-[#F9F7F5] inset-0 flex justify-center items-center"
      style={{ zIndex: 9999 }}
    >
      <svg
        version="1.1"
        id="Layer_1"
        width="100%"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 2000 2000"
        className="max-w-[500px]"
      >
        <g>
          <g>
            <g>
              <path
                className="st2"
                d="M911.88,1076.7L792.18,957c-10.73-10.73-10.73-28.13,0-38.86l119.7-119.7c10.73-10.73,28.13-10.73,38.86,0
				l119.7,119.7c10.73,10.73,10.73,28.13,0,38.86l-119.7,119.7C940.01,1087.43,922.61,1087.43,911.88,1076.7z"
              />
              <g>
                <path
                  className="st3"
                  d="M1049.26,1019.06l-119.7-119.7c-10.73-10.73-10.73-28.13,0-38.86l119.7-119.7
					c10.73-10.73,28.13-10.73,38.86,0l119.7,119.7c10.73,10.73,10.73,28.13,0,38.86l-119.7,119.7
					C1077.39,1029.79,1059.99,1029.79,1049.26,1019.06z"
                />
                <path
                  className="st4"
                  d="M1049.26,1134.34l-119.7-119.7c-10.73-10.73-10.73-28.13,0-38.86l119.7-119.7
					c10.73-10.73,28.13-10.73,38.86,0l119.7,119.7c10.73,10.73,10.73,28.13,0,38.86l-119.7,119.7
					C1077.39,1145.07,1059.99,1145.07,1049.26,1134.34z"
                />
              </g>
            </g>
          </g>
          <g className="st5" xmlns="http://www.w3.org/2000/svg">
            <g>
              <path d="M541.6,1266.5v-44.62h-17.54v-8.32h44.92v8.32h-17.55v44.62H541.6z" />
              <path d="M597.56,1266.5h-9.83v-52.94h9.83V1266.5z M625.7,1243.81h-28.96v-8.4h28.96V1243.81z M624.94,1213.56h9.83v52.94h-9.83     V1213.56z" />
              <path d="M670.16,1258.25h29.87v8.24h-39.7v-52.94h38.65v8.24h-28.81V1258.25z M669.41,1235.56h26.32v8.09h-26.32V1235.56z" />
              <path d="M754.56,1266.5v-52.94h21.78c4.69,0,8.71,0.76,12.06,2.27c3.35,1.51,5.94,3.68,7.75,6.5s2.72,6.18,2.72,10.06     c0,3.88-0.91,7.24-2.72,10.06c-1.81,2.82-4.4,4.99-7.75,6.5c-3.35,1.51-7.37,2.27-12.06,2.27h-16.33l4.39-4.61v19.89H754.56z      M764.39,1247.67l-4.39-4.77h15.88c4.34,0,7.6-0.92,9.79-2.76c2.19-1.84,3.29-4.42,3.29-7.75c0-3.38-1.1-5.97-3.29-7.79     c-2.19-1.81-5.46-2.72-9.79-2.72h-15.88l4.39-4.84V1247.67z" />
              <path d="M820.05,1266.5v-52.94h21.78c4.69,0,8.71,0.76,12.06,2.27c3.35,1.51,5.94,3.68,7.75,6.5s2.72,6.18,2.72,10.06     c0,3.88-0.91,7.22-2.72,10.02c-1.81,2.8-4.4,4.94-7.75,6.43c-3.35,1.49-7.37,2.23-12.06,2.23H825.5l4.39-4.46v19.89H820.05z      M829.88,1247.67l-4.39-4.69h15.88c4.34,0,7.6-0.93,9.79-2.8c2.19-1.86,3.29-4.46,3.29-7.79c0-3.38-1.1-5.97-3.29-7.79     c-2.19-1.81-5.46-2.72-9.79-2.72H825.5l4.39-4.84V1247.67z M854.69,1266.5l-13.39-19.21h10.51l13.46,19.21H854.69z" />
              <path d="M886.68,1266.5v-52.94h9.83v52.94H886.68z" />
              <path d="M922.14,1266.5v-52.94h8.09l33.2,40.76h-4.01v-40.76h9.76v52.94h-8.09l-33.2-40.76h4.01v40.76H922.14z" />
              <path d="M1005.48,1266.5v-44.62h-17.54v-8.32h44.92v8.32h-17.55v44.62H1005.48z" />
              <path d="M1100.24,1267.25c-4.14,0-8.09-0.59-11.87-1.78c-3.78-1.18-6.78-2.71-9-4.58l3.4-7.64c2.12,1.66,4.74,3.05,7.86,4.16     c3.13,1.11,6.33,1.66,9.61,1.66c2.77,0,5.02-0.3,6.73-0.91c1.71-0.61,2.97-1.42,3.78-2.46c0.81-1.03,1.21-2.21,1.21-3.52     c0-1.61-0.58-2.91-1.74-3.89c-1.16-0.98-2.66-1.77-4.5-2.35c-1.84-0.58-3.88-1.12-6.13-1.63c-2.24-0.5-4.49-1.1-6.73-1.78     c-2.24-0.68-4.29-1.56-6.13-2.65c-1.84-1.08-3.34-2.53-4.5-4.35c-1.16-1.81-1.74-4.13-1.74-6.96c0-2.87,0.77-5.51,2.31-7.9     c1.54-2.39,3.88-4.31,7.03-5.75c3.15-1.44,7.15-2.16,11.99-2.16c3.18,0,6.33,0.4,9.45,1.21c3.13,0.81,5.85,1.97,8.17,3.48     l-3.1,7.64c-2.37-1.41-4.82-2.46-7.34-3.14c-2.52-0.68-4.94-1.02-7.26-1.02c-2.72,0-4.93,0.33-6.62,0.98     c-1.69,0.66-2.92,1.51-3.71,2.57c-0.78,1.06-1.17,2.27-1.17,3.63c0,1.61,0.57,2.91,1.7,3.9c1.13,0.98,2.62,1.75,4.46,2.31     c1.84,0.56,3.9,1.1,6.16,1.63c2.27,0.53,4.52,1.12,6.77,1.78c2.24,0.66,4.28,1.51,6.13,2.57c1.84,1.06,3.33,2.49,4.46,4.31     c1.13,1.81,1.7,4.11,1.7,6.88c0,2.82-0.77,5.43-2.31,7.83c-1.54,2.4-3.9,4.31-7.07,5.75     C1109.09,1266.53,1105.08,1267.25,1100.24,1267.25z" />
              <path d="M1152.72,1266.5v-44.62h-17.54v-8.32h44.92v8.32h-17.55v44.62H1152.72z" />
              <path d="M1188.72,1266.5l23.82-52.94h9.68l23.9,52.94h-10.28l-20.49-47.72h3.93l-20.42,47.72H1188.72z M1199.69,1254.24     l2.65-7.71h28.59l2.65,7.71H1199.69z" />
              <path d="M1272.29,1266.5v-44.62h-17.54v-8.32h44.92v8.32h-17.55v44.62H1272.29z" />
              <path d="M1318.42,1266.5v-52.94h9.83v52.94H1318.42z" />
              <path d="M1378.69,1267.25c-4.13,0-7.94-0.68-11.42-2.04c-3.48-1.36-6.5-3.26-9.08-5.71c-2.57-2.44-4.56-5.32-5.97-8.62     c-1.41-3.3-2.12-6.92-2.12-10.85c0-3.93,0.7-7.55,2.12-10.85c1.41-3.3,3.4-6.18,5.97-8.62c2.57-2.44,5.6-4.35,9.08-5.71     c3.48-1.36,7.26-2.04,11.34-2.04c4.13,0,7.93,0.68,11.38,2.04c3.45,1.36,6.47,3.27,9.04,5.71c2.57,2.45,4.56,5.32,5.97,8.62     c1.41,3.3,2.12,6.92,2.12,10.85c0,3.93-0.71,7.56-2.12,10.89c-1.41,3.33-3.4,6.2-5.97,8.62c-2.57,2.42-5.58,4.31-9.04,5.67     C1386.54,1266.57,1382.77,1267.25,1378.69,1267.25z M1378.61,1258.63c2.67,0,5.14-0.45,7.41-1.36c2.27-0.91,4.23-2.2,5.9-3.9     c1.66-1.69,2.96-3.65,3.89-5.9c0.93-2.24,1.4-4.73,1.4-7.45c0-2.72-0.47-5.21-1.4-7.45c-0.93-2.24-2.23-4.21-3.89-5.9     c-1.66-1.69-3.63-2.99-5.9-3.9c-2.27-0.91-4.74-1.36-7.41-1.36s-5.13,0.45-7.37,1.36c-2.24,0.91-4.21,2.21-5.9,3.9     c-1.69,1.69-3,3.66-3.93,5.9c-0.93,2.24-1.4,4.73-1.4,7.45c0,2.67,0.47,5.14,1.4,7.41c0.93,2.27,2.23,4.25,3.9,5.94     c1.66,1.69,3.63,2.99,5.9,3.9C1373.47,1258.18,1375.94,1258.63,1378.61,1258.63z" />
              <path d="M1428.9,1266.5v-52.94h8.09l33.2,40.76h-4.01v-40.76h9.76v52.94h-8.09l-33.2-40.76h4.01v40.76H1428.9z" />
            </g>
          </g>

          <g className="st5" xmlns="http://www.w3.org/2000/svg">
            <g>
              <path d="M541.6,1266.5v-44.62h-17.54v-8.32h44.92v8.32h-17.55v44.62H541.6z" />
              <path d="M597.56,1266.5h-9.83v-52.94h9.83V1266.5z M625.7,1243.81h-28.96v-8.4h28.96V1243.81z M624.94,1213.56h9.83v52.94h-9.83     V1213.56z" />
              <path d="M670.16,1258.25h29.87v8.24h-39.7v-52.94h38.65v8.24h-28.81V1258.25z M669.41,1235.56h26.32v8.09h-26.32V1235.56z" />
              <path d="M754.56,1266.5v-52.94h21.78c4.69,0,8.71,0.76,12.06,2.27c3.35,1.51,5.94,3.68,7.75,6.5s2.72,6.18,2.72,10.06     c0,3.88-0.91,7.24-2.72,10.06c-1.81,2.82-4.4,4.99-7.75,6.5c-3.35,1.51-7.37,2.27-12.06,2.27h-16.33l4.39-4.61v19.89H754.56z      M764.39,1247.67l-4.39-4.77h15.88c4.34,0,7.6-0.92,9.79-2.76c2.19-1.84,3.29-4.42,3.29-7.75c0-3.38-1.1-5.97-3.29-7.79     c-2.19-1.81-5.46-2.72-9.79-2.72h-15.88l4.39-4.84V1247.67z" />
              <path d="M820.05,1266.5v-52.94h21.78c4.69,0,8.71,0.76,12.06,2.27c3.35,1.51,5.94,3.68,7.75,6.5s2.72,6.18,2.72,10.06     c0,3.88-0.91,7.22-2.72,10.02c-1.81,2.8-4.4,4.94-7.75,6.43c-3.35,1.49-7.37,2.23-12.06,2.23H825.5l4.39-4.46v19.89H820.05z      M829.88,1247.67l-4.39-4.69h15.88c4.34,0,7.6-0.93,9.79-2.8c2.19-1.86,3.29-4.46,3.29-7.79c0-3.38-1.1-5.97-3.29-7.79     c-2.19-1.81-5.46-2.72-9.79-2.72H825.5l4.39-4.84V1247.67z M854.69,1266.5l-13.39-19.21h10.51l13.46,19.21H854.69z" />
              <path d="M886.68,1266.5v-52.94h9.83v52.94H886.68z" />
              <path d="M922.14,1266.5v-52.94h8.09l33.2,40.76h-4.01v-40.76h9.76v52.94h-8.09l-33.2-40.76h4.01v40.76H922.14z" />
              <path d="M1005.48,1266.5v-44.62h-17.54v-8.32h44.92v8.32h-17.55v44.62H1005.48z" />
              <path d="M1100.24,1267.25c-4.14,0-8.09-0.59-11.87-1.78c-3.78-1.18-6.78-2.71-9-4.58l3.4-7.64c2.12,1.66,4.74,3.05,7.86,4.16     c3.13,1.11,6.33,1.66,9.61,1.66c2.77,0,5.02-0.3,6.73-0.91c1.71-0.61,2.97-1.42,3.78-2.46c0.81-1.03,1.21-2.21,1.21-3.52     c0-1.61-0.58-2.91-1.74-3.89c-1.16-0.98-2.66-1.77-4.5-2.35c-1.84-0.58-3.88-1.12-6.13-1.63c-2.24-0.5-4.49-1.1-6.73-1.78     c-2.24-0.68-4.29-1.56-6.13-2.65c-1.84-1.08-3.34-2.53-4.5-4.35c-1.16-1.81-1.74-4.13-1.74-6.96c0-2.87,0.77-5.51,2.31-7.9     c1.54-2.39,3.88-4.31,7.03-5.75c3.15-1.44,7.15-2.16,11.99-2.16c3.18,0,6.33,0.4,9.45,1.21c3.13,0.81,5.85,1.97,8.17,3.48     l-3.1,7.64c-2.37-1.41-4.82-2.46-7.34-3.14c-2.52-0.68-4.94-1.02-7.26-1.02c-2.72,0-4.93,0.33-6.62,0.98     c-1.69,0.66-2.92,1.51-3.71,2.57c-0.78,1.06-1.17,2.27-1.17,3.63c0,1.61,0.57,2.91,1.7,3.9c1.13,0.98,2.62,1.75,4.46,2.31     c1.84,0.56,3.9,1.1,6.16,1.63c2.27,0.53,4.52,1.12,6.77,1.78c2.24,0.66,4.28,1.51,6.13,2.57c1.84,1.06,3.33,2.49,4.46,4.31     c1.13,1.81,1.7,4.11,1.7,6.88c0,2.82-0.77,5.43-2.31,7.83c-1.54,2.4-3.9,4.31-7.07,5.75     C1109.09,1266.53,1105.08,1267.25,1100.24,1267.25z" />
              <path d="M1152.72,1266.5v-44.62h-17.54v-8.32h44.92v8.32h-17.55v44.62H1152.72z" />
              <path d="M1188.72,1266.5l23.82-52.94h9.68l23.9,52.94h-10.28l-20.49-47.72h3.93l-20.42,47.72H1188.72z M1199.69,1254.24     l2.65-7.71h28.59l2.65,7.71H1199.69z" />
              <path d="M1272.29,1266.5v-44.62h-17.54v-8.32h44.92v8.32h-17.55v44.62H1272.29z" />
              <path d="M1318.42,1266.5v-52.94h9.83v52.94H1318.42z" />
              <path d="M1378.69,1267.25c-4.13,0-7.94-0.68-11.42-2.04c-3.48-1.36-6.5-3.26-9.08-5.71c-2.57-2.44-4.56-5.32-5.97-8.62     c-1.41-3.3-2.12-6.92-2.12-10.85c0-3.93,0.7-7.55,2.12-10.85c1.41-3.3,3.4-6.18,5.97-8.62c2.57-2.44,5.6-4.35,9.08-5.71     c3.48-1.36,7.26-2.04,11.34-2.04c4.13,0,7.93,0.68,11.38,2.04c3.45,1.36,6.47,3.27,9.04,5.71c2.57,2.45,4.56,5.32,5.97,8.62     c1.41,3.3,2.12,6.92,2.12,10.85c0,3.93-0.71,7.56-2.12,10.89c-1.41,3.33-3.4,6.2-5.97,8.62c-2.57,2.42-5.58,4.31-9.04,5.67     C1386.54,1266.57,1382.77,1267.25,1378.69,1267.25z M1378.61,1258.63c2.67,0,5.14-0.45,7.41-1.36c2.27-0.91,4.23-2.2,5.9-3.9     c1.66-1.69,2.96-3.65,3.89-5.9c0.93-2.24,1.4-4.73,1.4-7.45c0-2.72-0.47-5.21-1.4-7.45c-0.93-2.24-2.23-4.21-3.89-5.9     c-1.66-1.69-3.63-2.99-5.9-3.9c-2.27-0.91-4.74-1.36-7.41-1.36s-5.13,0.45-7.37,1.36c-2.24,0.91-4.21,2.21-5.9,3.9     c-1.69,1.69-3,3.66-3.93,5.9c-0.93,2.24-1.4,4.73-1.4,7.45c0,2.67,0.47,5.14,1.4,7.41c0.93,2.27,2.23,4.25,3.9,5.94     c1.66,1.69,3.63,2.99,5.9,3.9C1373.47,1258.18,1375.94,1258.63,1378.61,1258.63z" />
              <path d="M1428.9,1266.5v-52.94h8.09l33.2,40.76h-4.01v-40.76h9.76v52.94h-8.09l-33.2-40.76h4.01v40.76H1428.9z" />
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default Preloader;
