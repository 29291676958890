import message from "../../assets/images/icons/email.png"
import location from "../../assets/images/icons/location.png"
import phone from "../../assets/images/icons/phone number.png"

function Cards() {
    return (
        <div className='w-full space-y-20 pt-14'>
            <div className='w-full flex justify-center'>
                <ul className='space-y-7 px-4 md:px-0 md:space-y-0 flex flex-col xl:flex-wrap w-full lg:max-w-[1200px] md:justify-evenly items-start'>
                    <div className=' flex justify-center xl:justify-between space-y-8 md:space-y-0 w-full flex-wrap'>
                        <div className='w-full md:basis-1/2 xl:basis-1/4  md:px-4 xl:px-0'>
                            <li className='border border-[#e6e6e6] h-[116px] px-5 md:px-0 flex items-center md:pl-8 gap-5'>
                                <img src={message} alt="" className='w-[50px] h-[50px]' />
                                <div>
                                    <h1 className='text-[#000000] tracking-tighter md:tracking-normal h-[60px] text-[18px] cards '>Send us an email</h1>
                                    <p className='text-[#555555] relative bottom-4 text-[14px] cards '>info@theprintstation.org</p>
                                </div>
                            </li>
                        </div>
                        <div className='w-full md:basis-1/2 xl:basis-2/7 md:pr-4 md:pl-2 xl:pl-0'>
                            <li className=' border border-[#e6e6e6] sm:h-[116px] h-[141px] md:h-[141px] xl:h-[171px] lg:h-[116px] flex px-3 md:pl-5 md:px-0 gap-5 xl:gap-2 items-center'>
                                <img src={location} alt="When And Where - New Market Icon@seekpng.com" className='w-[55px] h-[55px]' />
                                <h1 className='flex flex-col justify-center space-y-3 bottom-2 md:bottom-3 items-start'>
                                    <h2 className='text-[#000000] h-[35px] text-[18px] cards '>Office</h2>
                                    <h3 className='text-[#555555] text-[14px] cards'>3101 N. Central Ave, Ste 183 #3416 Phoenix, Arizona 85012</h3>
                                </h1>
                            </li>
                        </div>
                        <div className='w-full md:basis-1/2 md:right-20 xl:right-0 relative md:top-5 xl:top-0 xl:basis-1/4 md:px-4 xl:px-0'>
                            <li className='border border-[#e6e6e6] h-[116px] flex px-5 md:px-0 md:pl-8 gap-5  items-center'>
                                <img src={phone} alt="When And Where - New Market Icon@seekpng.com" className='w-[55px] h-[55px]' />
                                <h1 className='flex flex-col justify-center space-y-3 bottom-3 items-start'>
                                    <h1 className='text-[#000000] h-[40px] text-[18px] cards tracking-tighter md:tracking-normal'>Phone Number</h1>
                                    <h3 className='text-[#555555] text-[14px] cards '>(310-500-7577)</h3>
                                </h1>
                            </li>
                        </div>
                    </div>
                </ul>
            </div>
            <main className='w-full text-white flex justify-center p-4 bg-[#000000] h-[133px] sm:h-[110px] md:h-[88px] text-center'>
                <ul className='md:flex text-start sm:text-center justify-between items-center md:h-[30px] sm:basis-2/3'>
                    <p className='text-[14px] text-white leading-6 cards'>3101 N. Central Ave, Ste 183 #3416 Phoenix, Arizona 85012</p>
                    <h1 className='text-[14px] text-white right-3 relative bottom-5 sm:bottom-4 md:bottom-0 sm:right-5 text-center'>(310)-500-7577</h1>
                </ul>
            </main>
        </div>
    )
}

export default Cards
