import React, { memo, useState,useEffect } from "react";
import MobileNavlist from "./MobileNavlist";
import { BiMenu } from "react-icons/bi";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/images/logoo.png";
import Container from "./Container";
const FixedNavbar = memo(() => {
  const [isVisible, setIsVisible] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;

      // Customize the scroll threshold based on your needs
      if (scrollTop > 80) {
        setShowNavbar(true);
      } else {
        setShowNavbar(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  return (
    <div id="nav" className={`fixed left-0 right-0 z-50 transition-all bg-white duration-700 ${
      showNavbar ? 'bg-white shadow-lg top-0' : 'top-[-100px]'
    }`}>
      <Container>
      <div className="flex justify-between p-4 items-center">
        <div>
          <Link to="/">
            <img src={logo} alt="WabbitCard" className=" w-24" />
          </Link>
        </div>
        <div className=" hidden md:block">
          <ul className="flex items-center space-x-10 text-base font-medium cursor-pointer">
          <li className={`${useLocation()?.pathname==="/"?" text-[#DD1D26]":"text-black hover:text-[#DD1D26]"}`}>
          <Link to="/">
          Home
          </Link>
        </li>
        <li className={`${useLocation()?.pathname==="/contact"?" text-[#DD1D26]":"text-black hover:text-[#DD1D26]"}`}>
          <Link to="/contact">Contact Us</Link>
        </li>
          </ul>
        </div>
        <div className="flex md:hidden items-center justify-center">
          <button
            className=" flex ml-auto  z-10 cursor-pointer text-3xl px-3 border border-solid border-transparent rounded bg-transparent lg:hidden outline-none focus:outline-none"
            type="button"
            onClick={toggleVisibility}
          >
            <BiMenu />
          </button>
        </div>
      </div>
      </Container>
      <MobileNavlist
        isVisible={isVisible}
        toggleVisibility={toggleVisibility}
      />
    </div>
  );
});

export default FixedNavbar;
