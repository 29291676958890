import React, { memo } from "react";
import ServiceCard from "./Utilities/ServiceCard";
import Container from "./Utilities/Container";
import marketing from "../assets/images/icons/flyer.png";
import bags from "../assets/images/icons/bags.png";
import pizza from "../assets/images/icons/pizza-box.png";
import cupcake from "../assets/images/icons/cupcake.png";
import food from "../assets/images/icons/food-delivery.png";
import coffee from "../assets/images/icons/coffee-cup.png";
const OurServices = memo(() => {
  const ChoseData = [
    {
      id: "Card1",
      img: pizza,
      head: "Custom Pizza Boxes",
      text: 'Experience the pinnacle of pizza delight with customizable pizza boxes from The Print Station. Choose from 7", 10", 13", 16", or custom sizes. Showcase your brand while keeping your pizza piping hot and tantalizingly fresh.',
    },
    {
      id: "Card2",
      img: cupcake,
      head: "Customized Bakery boxes",
      text: "Our customized bakery boxes are just the ticket for flaunting your baked treats with panache. Whether you are slinging cakes, cookies, donuts, cupcakes, or any other sweet delights, our bakery boxes will keep them fresh, looking fantastic, and giving your brand a boost all at once.",
    },
    {
      id: "Card3",
      img: bags,
      head: "Customized Bags",
      text: "Our to-go bags are crafted with a blend of convenience and flair, featuring durable handles and a variety of sizes to accommodate all your items. We provide an array of bag options, such as delivery bags, carryout bags, and food bags, tailored to fit your requirements.",
    },
    {
      id: "Card4",
      img: food,
      head: "Custom Food Packaging",
      text: "Our signature food accessories are the ideal cherry on top for any meal. Whether you're dishing out fries, wraps, burgers, shawarmas, or any other chow, our pouches and boxes will ensure your products look enticing while giving your brand some extra sizzle.",
    },
    {
      id: "Card5",
      img: marketing,
      head: "Customized Marketing Material",
      text: "Here at The Print Station, we're firm believers that your business's identity is key to your success. That's why we offer an array of personalized business branding goods, such as business cards, flyers, and booklets. Our products are engineered to help you craft a professional image that'll knock the socks off your customers and leave a lasting impression.",
    },
    {
      id: "Card6",
      img: coffee,
      head: "Custom Branded Cups",
      text: "We've got tailor-made dessert cups perfect for showing off your chilly and hot drinks. Whether you're dishing out shakes, coffee, ice cream, frozen yogurt, or any other sweet treat, our branded cups will keep your desserts looking cool and enticing while boosting your brand. With our wide assortment of sizes and designs, you're bound to find the perfect cup for your business.",
    },
  ];
  return (
    <Container>
      <div className=" pb-[50px]">
        <div className="relative text-2xl md:text-3xl lg:text-4xl font-medium flex justify-center mb-3 title-box text-[#4e1e10]">
          <h2>Our Services</h2>
        </div>
        <div className="flex flex-col md:flex-row md:flex-wrap md:justify-between lg:justify-evenly ">
          {ChoseData.map((obj) => (
            <ServiceCard
              key={obj.id}
              text={obj.text}
              img={obj.img}
              head={obj.head}
            />
          ))}
        </div>
      </div>
    </Container>
  );
});

export default OurServices;
